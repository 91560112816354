import { Component } from '@angular/core';

@Component({
  selector: 'sw-karla',
  template: `
    <div style="padding: 20px; text-align: center;">
      <h1>Karla Test Route</h1>
      <p>This is a test route at /karla</p>
    </div>
  `
})
export class KarlaComponent {
  constructor() {}
}
