import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { UIService } from 'src/app/core/services/ui.service';
import { DataNode, LibraryLink } from 'src/app/model/data-node.model';
import { AttributeEditorService } from 'src/app/modules/attributes/attribute-editor.service';
import { DataNodeService } from 'src/app/modules/project/services/data-node.service';
import { SearchService } from 'src/app/services/search.service';
import { cloneDeep } from 'lodash';
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { wDragDropListEvent } from 'src/app/modules/wTree/tree-node.model';
import { animations } from './animations';
import { ProjectLiveUpdatesService } from 'src/app/services/liveupdates/project-live-updates.service';
import { DragService } from 'src/app/modules/wTree/drag-service.service';
import { UpdateDataNodeMessage } from 'src/app/services/liveupdates/messages';
import { UnitConversionService } from '../services/unit-conversion.service';
import { AttributesComponent } from '../../attributes/attributes.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { LibraryService } from '../../library/library.service';

export const SW_HIDDEN_PREFIX = '__sw-hidden__';

@Component({
  selector: 'sw-data-node',
  templateUrl: './data-node.component.html',
  styleUrls: ['./css/data-node.component.scss', './css/drop-zone.scss'],
  animations: animations,
})
export class DataNodeComponent implements OnInit, AfterContentInit, OnDestroy {

  private queryChannel: string = "data-node-channel";

  constructor(
    private attributeEditorService: AttributeEditorService,
    private searchService: SearchService,
    private dataNodeService: DataNodeService,
    private uiService: UIService,
    private liveUpdateService: ProjectLiveUpdatesService,
    private libraryService: LibraryService,
    private dragService: DragService,
    private unitConversion: UnitConversionService,
    private domSanitizer: DomSanitizer
  ) {}

  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onDuplicate: EventEmitter<any> = new EventEmitter();

  @Input() parent: DataNode;
  @Input() node: DataNode;
  @Output() nodeChange: EventEmitter<DataNode> = new EventEmitter<DataNode>();

  @ViewChild(AttributesComponent) attributeComponent;

  @Input() indentLevel = 0;
  expanded = true;
  isHidden = false;
  swHiddenNode = false;

  // dragging info
  @Input() parentIsDragged = false;
  dragging = false;
  isDragging(): boolean {
    return this.dragging || this.parentIsDragged;
  }

  state:
    | 'Init'
    | 'LoadingChildren'
    | 'FailedLoadingChildren'
    | 'ChildrenLoaded' = 'Init';

  searchSub: Subscription;
  liveUpdateSub: Subscription;
  libraryItemSub: Subscription;
  newNodeSub: Subscription;

  ngOnInit(): void {
    this.swHiddenNode = this.node.name.startsWith(SW_HIDDEN_PREFIX);

    this.initSearchSubscription();
    this.initLiveUpdateSubscription();
    this.initLibraryItemSubscription();

    if (this.parent == undefined) {
      // root nodes listen for new events that come from the plugin header
      this.initNewNodeSubscription();
    }
  }

  ngOnDestroy() {
    this.searchSub?.unsubscribe();
    this.liveUpdateSub?.unsubscribe();
    this.libraryItemSub?.unsubscribe();
    this.newNodeSub?.unsubscribe();
  }

  private initNewNodeSubscription() {
    this.newNodeSub = this.dataNodeService.addDataNodeChild.subscribe((q) => {
      this.addDataNodeChild();
    });
  }

  private initSearchSubscription() {
    this.searchSub = this.searchService.query$.subscribe((q) => {
      this.isHidden = false;

      if (!q || !this.node || !this.node.name) {
        return;
      }

      if (q.libraryItems.length > 0) {
        var hasQueriedLibraryItem = false;
        const searchIDs = q.libraryItems.map((l) => l.id);

        // intersect arrays of ids
        const intersection = this.node.libraryLinks
          .map((l) => String(l.itemID))
          .filter((libraryItemID) => searchIDs.includes(libraryItemID));

        if (intersection.length < 1) {
          this.isHidden = true;
          return;
        }
      }

      if (q.query && q.channel === this.queryChannel) {
        // TODO: optimise this by sending the lowercased query string
        const theQuery = q.query.replace(/\s+/g, '').toLowerCase();
        const nameLC = this.node.name.toLowerCase().replace(/\s+/g, '');
        this.isHidden = !nameLC.includes(theQuery);
      }
    });
  }

  linkPluginIcon(link: LibraryLink) {
    return 'icon/plugins/' + link.pluginID + '/icon.svg';
  }

  private initLiveUpdateSubscription() {
    this.liveUpdateSub = this.liveUpdateService.updateDataNodeSignal.subscribe(
      (m: UpdateDataNodeMessage) => {
        if (m.nodeID != this.node.id) return;
        this.reloadNode();
      }
    );
  }

  private initLibraryItemSubscription() {
    this.libraryItemSub?.unsubscribe();

    if (!this.node.libraryLinks || this.node.libraryLinks.length == 0) return;

    // it only supports a single library item
    const currentLink = this.node.libraryLinks[0];

    this.libraryItemSub =
      this.libraryService.updatedLibraryItemSignal.subscribe(async (m) => {
        if (m.item.id == currentLink.itemID) {
          this.node.libraryLinks[0].item.name = m.item.name;
          console.log(m.item.name);

          if (currentLink.item.thumbnailHash != m.item.thumbnailHash) {
            // needs to update the thumbnail
            currentLink.item.thumbnail =
              await this.libraryService.fetchThumbnail(currentLink.item);
          }
        }
      });
  }

  async ngAfterContentInit() {
    await this.loadChildren();
  }

  async reloadNode() {
    try {
      await this.dataNodeService.reloadNode(this.node);
      this.reloadChangedChildren();
    } catch (err) {
      console.error(err);
      console.error("can't reload node: " + this.node.id);
    }
  }

  async reloadChangedChildren() {
    for (
      var childIDIndex = 0;
      childIDIndex < this.node.childrenIDs.length;
      childIDIndex++
    ) {
      var childID = this.node.childrenIDs[childIDIndex];
      const childIndex = this.node.children.findIndex((c) => c.id == childID);

      if (childIDIndex == childIndex) {
        // the child is already loaded and placed at the right index
        continue;
      }

      if (childIndex == -1) {
        // the childID is not found / not loaded
        try {
          var node = await this.dataNodeService.getNode(childID);
          // insert loaded node at childIDindex
        } catch (err) {
          console.error("can't load node");
          console.error(err);
        }
        this.node.children.splice(childIDIndex, 0, node);
        continue;
      }

      // Child at a different index
      // swap it/move it to the correct position
      moveItemInArray(this.node.children, childIndex, childIDIndex);
    }

    // if the children array is larger, it means at least one was deleted
    const delta = this.node.children.length - this.node.childrenIDs.length;
    if (delta > 0) {
      this.node.children.splice(this.node.childrenIDs.length, delta);
    }
  }

  async loadChildren() {
    // console.warn("DATANODE.loadChildren called on :" + this.node.id)
    // console.warn(cloneDeep(this.node))
    if (
      this.node.childrenIDs === undefined ||
      this.node.childrenIDs.length == 0
    ) {
      this.node.children = [];
      this.state = 'ChildrenLoaded';
      return;
    }

    this.state = 'LoadingChildren';
    try {
      this.node.children = await this.dataNodeService.loadChildren(this.node);
      this.state = 'ChildrenLoaded';
      // this.nodeChange.next(this.node)
    } catch (e) {
      this.state = 'FailedLoadingChildren';
      this.uiService.showErrors(e);
    }
  }

  dragStarted(event) {
    // console.log("node: dragStarted")
    this.dragging = true;
    this.attributeEditorService.deselectNode();
    // this.dragService.nodeDragStarted(this.node)
  }

  dragReleased(event) {
    console.log('node: dragReleased');
    this.dragging = false;
    // this.dragService.nodeDragEnded()
  }

  dropped(event: wDragDropListEvent) {
    // this.dragService.nodeDragCancel()

    if (event.from.sourceList === event.to.targetList) {
      moveItemInArray(event.to.targetList, event.fromIndex, event.toIndex);

      this.updateNodes([event.to.target as DataNode]);
    } else {
      transferArrayItem(
        event.from.sourceList,
        event.to.targetList,
        event.fromIndex,
        event.toIndex
      );

      this.updateNodes([
        event.from.source as DataNode,
        event.to.target as DataNode,
      ]);
    }
  }

  hasChildren(): boolean {
    if (this.node == undefined) {
      return false;
    }

    if (this.node.childrenIDs === undefined) {
      this.node.childrenIDs = [];
    }

    // TODO: check this
    if (this.node.childrenIDs.length || this.node.childrenIDs.length) {
      return true;
    }
    return false;
  }

  async duplicateChildAtIndex(index) {
    this.uiService.disableUI(true);

    try {
      const newNode: DataNode = await this.dataNodeService.duplicate(
        this.node.children[index]
      );

      this.node.children.splice(index + 1, 0, newNode);
      await this.dataNodeService.updateNodes([this.node]);
      //  this.nodeChange.next(this.node)
    } catch (err) {
      console.error(err);
      alert(err);
    }
    this.uiService.enableUI();
  }

  async deleteChildAtIndex(index) {
    this.uiService.disableUI(true);
    try {
      const delResponse = await this.dataNodeService.deleteNode(
        this.node.children[index]
      );
      var nodeClone = cloneDeep(this.node);
      nodeClone.children.splice(index, 1);
      await this.dataNodeService.updateNodes([nodeClone]);
      this.node = nodeClone;
      //  this.nodeChange.next(this.node)
    } catch (err) {
      alert("can't remove node");
    }
    this.uiService.enableUI();
  }

  async addDataNodeChild() {
    this.uiService.disableUI(true);
    try {
      const name = 'New Item';

      // 1. create new node
      const newNode: DataNode = await this.dataNodeService.createNode({
        id: undefined,
        name: name,
        attributes: [],
        children: undefined,
        childrenIDs: undefined,
        treeTag: this.node.treeTag,
        libraryLinks: [],
      });

      // 2. add new node to node.children clone
      var tempNode = cloneDeep(this.node);
      tempNode.children = tempNode.children || [];
      tempNode.children.push(newNode);
      await this.dataNodeService.updateNodes([tempNode]);

      // 3. set node.children
      this.node = tempNode;
      //  this.nodeChange.next(tempNode)
    } catch (err) {
      console.error(err);
      // alert("can't create node")
    }

    this.uiService.enableUI();
  }

  editAttributes() {
    this.attributeEditorService.open(this.node);
    setTimeout(() => {
      this.attributeComponent.onEdit();
    }, 0);
  }

  toggleAttributes() {
    if (this.isDragging()) {
      return;
    }
    if (this.attributeEditorService.currentNodeReference == this.node) {
      //close
      this.attributeEditorService.deselectNode();
    } else {
      this.attributeEditorService.open(this.node);
    }
  }

  nameClick() {
    if (this.isOpened() == false) {
      this.toggleAttributes();
    }
  }

  endEditingName() {
    console.log('Node: ');
    console.log(this.node);
    setTimeout(() => {
      if (this.node.name == '') {
        this.node.name = '-';
      }
      this.updateNodes([this.node]);
    }, 0);
  }

  // showing attributes
  isOpened(): boolean {
    return this.attributeEditorService.currentNodeReference === this.node;
  }

  // selected when dragging
  isSelected(): boolean {
    // return false
    return this.attributeEditorService.currentNodeReference === this.node;
  }

  nodeByID(index, node) {
    if (this.parent === undefined) {
      return node.id;
    }
    return this.parent.id + node.id;
  }

  async updateNodes(nodes: DataNode[]) {
    this.uiService.disableUI(true);
    try {
      await this.dataNodeService.updateNodes(nodes);
    } catch (e) {
      console.error("can't update nodes");
    }
    this.uiService.enableUI();
  }

  hasPrice(node: DataNode): boolean {
    return (
      node.attributes.findIndex((a) => a.tag == '_gardn8_object_price') > -1
    );
  }

  itemPrice(node: DataNode): string {
    var attr = node.attributes.find((a) => a.tag == '_gardn8_object_price');
    return this.unitConversion.valueFromRawValueAndUnit(attr);
  }

  thumbnailData(data): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustUrl(data);
  }

  trimName(name: string): string {
    // could be improved:
    // https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript

    if (name.length > 15) {
      return name.slice(0, 13) + '...';
    } else {
      return name;
    }
  }
}
