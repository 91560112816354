import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { LibraryLabel } from 'src/app/model/library-label.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl } from '@angular/forms';
import { map, tap } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable, Subscription } from 'rxjs';
import { SearchService } from 'src/app/services/search.service';
import { LibraryLabelsService } from 'src/app/modules/library/library-labels.service';
import { LibraryItem } from 'src/app/model/library-item.model';

@Component({
  selector: 'sw-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent implements OnInit, OnDestroy {
  selectedLabels: LibraryLabel[] = [];
  selectedLibraryItems: LibraryItem[] = [];

  @Input() hideLabels: boolean;
  @Input() channel: string;

  allLabelsSubscription: Subscription;
  allLabels: LibraryLabel[] = [];
  filteredLabels: Observable<LibraryLabel[]>;

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  searchCtrl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];

  querySubscription: Subscription;

  constructor(
    public searchService: SearchService,
    public libraryLabelsService: LibraryLabelsService
  ) {}

  ngOnDestroy(): void {
    this.allLabelsSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.filteredLabels = this.searchCtrl.valueChanges.pipe(
      // startWith(null),
      map((input) => this._filterLabels(input)),
      tap((v) => {
        this.updateQuery();
      })
    );

    if (this.hideLabels != true) {
      this.allLabelsSubscription = this.libraryLabelsService.labels.subscribe(
        (labels) => (this.allLabels = labels)
      );
    }

    if (this.channel) {
      this.searchService.registerChannel(this.channel);
    }

    this.subscribeToSearchQuery();
  }

  searchQueryNotEmpty() {
    return (
      this.selectedLabels.length > 0 ||
      this.searchCtrl.value ||
      this.selectedLibraryItems.length > 0
    );
  }

  clearInput() {
    this.selectedLabels = [];
    this.selectedLibraryItems = [];

    this.searchInput.nativeElement.value = '';
    this.searchCtrl.setValue(null);
    this.updateQuery();
  }

  onAutocompleteSelected(event: MatAutocompleteSelectedEvent) {
    this.selectedLabels.push(event.option.value);

    this.searchInput.nativeElement.value = '';
    this.searchCtrl.setValue(null);
    this.updateQuery();
  }

  subscribeToSearchQuery() {
    this.querySubscription = this.searchService.query$.subscribe((q) => {
      this.searchCtrl.patchValue(q.query, { emitEvent: false });
      this.selectedLabels = q.labels;
      this.selectedLibraryItems = q.libraryItems;
    });
  }

  updateQuery() {

    const channel = (this.channel)
      ? this.channel
      : "";

    this.searchService.setQuery(
      {
        labels: this.selectedLabels,
        query: this.searchInput.nativeElement.value,
        libraryItems: this.selectedLibraryItems,
      }, 
      channel
    );
  }

  private _filterLabels(value: LibraryLabel | string | null): LibraryLabel[] {
    var notSelected = this.allLabels.filter(
      (label) => !this.selectedLabels.includes(label)
    );

    if (value == null) {
      return notSelected;
    }

    if (typeof value == 'string') {
      const filterValue = value.toLowerCase();
      return notSelected.filter((label) =>
        label.name.toLowerCase().includes(filterValue)
      );
    }

    if (typeof value == 'object') {
      return notSelected.filter((label) => label == value);
    }

    return [];
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      const label = this.allLabels.find((l) => l.name == value);
      if (label) {
        this.selectedLabels.push(label);
        event.chipInput!.clear();
      } else {
        // TODO: it must be a library item
      }
    }

    // refresh the autosuggestion list
    this.searchCtrl.setValue(null);
  }

  remove(label: LibraryLabel): void {
    const index = this.selectedLabels.indexOf(label);

    if (index >= 0) {
      this.selectedLabels.splice(index, 1);
    }

    // refresh the autosuggestion list
    this.searchCtrl.setValue(this.searchCtrl.value);
    this.updateQuery();
  }

  removeItem(item: LibraryItem): void {
    const index = this.selectedLibraryItems.indexOf(item);

    if (index >= 0) {
      this.selectedLibraryItems.splice(index, 1);
    }
    this.updateQuery();
  }
}
