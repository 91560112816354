import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { Project } from 'src/app/model/project.model';
import { DataNodeService } from 'src/app/modules/project/services/data-node.service';
import { DataNode } from 'src/app/model/data-node.model';
import { Subscription } from 'rxjs';
import { UIService } from 'src/app/core/services/ui.service';
import { delay } from 'rxjs/operators';
import { SketchupService } from 'src/app/services/sketchup.service';
import { MatDialog } from '@angular/material/dialog';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { ProjectData } from 'src/app/model/project-data.model';
import { TranslateService } from '@ngx-translate/core';
import { SearchService } from 'src/app/services/search.service';
import { NodeLinksService } from '../../library/node-links.service';

@Component({
  selector: 'sw-project-data-list',
  templateUrl: './project-data-list.component.html',
  styleUrls: ['./project-data-list.component.scss'],
  providers: [NodeLinksService],
})
export class ProjectDataListComponent implements OnInit, OnDestroy {

  project: Project;
  errorMessage: string;
  queryChannel: string = "data-node-channel";

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private dataNodeService: DataNodeService,
    private uiService: UIService,
    public sketchupService: SketchupService,
    public dialog: MatDialog,
    private translate: TranslateService,
    public searchService: SearchService,
    private nls: NodeLinksService
  ) {}

  routeSubscriber: Subscription;
  projectSubscription: Subscription;
  searchSubscription: Subscription;

  showSearch = false;

  startSearching(): void {
    this.showSearch = true;
  }

  ngOnInit(): void {

    this.projectSubscription = this.projectService.project.subscribe((p) => {
      this.project = p;
    });

    this.searchSubscription = this.searchService.query$.subscribe((q) => {

      if (q.libraryItems.length || q.labels.length || q.query.length) {
        this.showSearch = (q.channel === this.queryChannel);
      }
    });
    // observer for `id` param in route
    this.setupProjectRouteHandler();
  }

  closeProject() {
    if (this.showSearch) {
      this.showSearch = false;
    } else {
      this.projectService.closeProject();
    }
    this.uiService.enableUI();
  }

  setupProjectRouteHandler() {
    this.routeSubscriber?.unsubscribe();
    this.routeSubscriber = this.route.params
      .pipe(delay(0))
      .subscribe((params) => this.updateRoute(params));
  }

  async updateRoute(params) {
    this.uiService.disableUI(true);
    this.errorMessage = undefined;
    try {
      this.project = await this.projectService.fetchProject(params.id);
      // console.log('fetched project')
      this.projectService.setProjectReference(this.project, false);
      // console.log('set Project reference to Project in Edit view')
    } catch (e) {
      this.errorMessage = this.translate.instant('PROJECT.CANT_LOAD');
      console.error(e);
      this.uiService.showErrors(e);
    }
    this.uiService.enableUI();
  }

  ngOnDestroy() {
    this.projectSubscription?.unsubscribe();
    this.routeSubscriber?.unsubscribe();
  }

  openSettings() {
    this.projectService.openSettings(this.project.id);
  }

  openPlugins() {
    this.projectService.openPlugins(this.project.id);
  }

  async updateProject() {
    this.uiService.disableUI(true);
    try {
      const updatedProject = await this.projectService.updateProjectMetadata(
        this.project
      );
      this.projectService.setProjectReference(updatedProject, true);
    } catch (err) {
      this.uiService.showErrors(err);
    }
    this.uiService.enableUI();
  }

  addNode(node: DataNode) {
    this.dataNodeService.updateNodes([node]);
  }

  async drop(event: CdkDragDrop<ProjectData[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

    this.uiService.disableUI();

    try {
      await this.projectService.reorderProjectData(this.project);
    } catch (err) {
      this.uiService.showErrors(err);
    }

    this.uiService.enableUI();
  }
}
