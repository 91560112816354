import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { ClickOutsideModule } from 'ng-click-outside';
import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './modules/app-routing.module';
import { AppComponent } from './app.component';
import { TopHeaderComponent } from './components/top-header/top-header.component';

import { SplashComponent } from './components/splash/splash.component';
import { LoginComponent } from './components/login/login.component';
import { KarlaComponent } from './components/test/karla.component';

import { ObserveIntersectionDirective } from './shared/intersection-observer.directive';
import { authInterceptorProviders } from './core/helpers/auth.interceptor';
import { SharedModule } from './shared/shared.module';

import { NotificationsService } from './components/sw-notification/notifications.service';
import { NotificationComponent } from './components/sw-notification/notification.component';

import { ProjectBrowserModule } from './modules/project-browser/project-browser.module';
import { ProjectModule } from './modules/project/project.module';
import { BrowserModule } from '@angular/platform-browser';

import { LanguageService } from './core/services/language.service';
import { HttpClient } from '@angular/common/http';
import { RabbitMQModule } from './modules/rmq/rabbit-mq.module';
import { ModelManagerModule } from './modules/model-manager/model-manager.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Fabric8Module } from './modules/fabric8/fabric8.module';

function createTranslateLoader(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
const translate = TranslateModule.forRoot({
  loader: {
    provide: TranslateLoader,
    useFactory: createTranslateLoader,
    deps: [HttpClient],
  },
  isolate: false,
});

@NgModule({
  declarations: [
    AppComponent,
    TopHeaderComponent,
    SplashComponent,
    LoginComponent,
    KarlaComponent,
    ObserveIntersectionDirective,
    NotificationComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,

    RabbitMQModule,

    ProjectBrowserModule,
    ProjectModule,

    ModelManagerModule,
    Fabric8Module,

    FormsModule,
    BrowserAnimationsModule,
    DragDropModule,
    ClickOutsideModule,

    CoreModule,
    SharedModule,
    translate,
  ],
  providers: [
    authInterceptorProviders,
    NotificationsService,
    // live updates services
    LanguageService,
    translate.providers,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
