import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProjectsListComponent } from './project-browser/list/projects-list.component';
import { SplashComponent } from '../components/splash/splash.component';
import { LoginComponent } from '../components/login/login.component';
import { KarlaComponent } from '../components/test/karla.component';

const routes: Routes = [
  {
    path: 'splash',
    component: SplashComponent,
  },
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent,
  },
  {
    path: 'karla',
    pathMatch: 'full',
    component: KarlaComponent,
  },
  {
    outlet: 'project',
    path: ':id',
    loadChildren: () =>
      import('./project/project.module').then((m) => m.ProjectModule),
  },
  {
    path: '',
    component: ProjectsListComponent,
  },
  {
    path: '',
    loadChildren: () =>
      import('./fabric8/fabric8.module').then((m) => m.Fabric8Module),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
