<mat-toolbar-row id="header">
  <button id="hamburger" mat-button [matMenuTriggerFor]="mainMenu">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #mainMenu="matMenu">
    <button mat-menu-item><a href="/">Projects</a></button>
    <button mat-menu-item>
      <a [routerLink]="['/fabric8']" routerLinkActive="active">
        Fabric8
      </a>
    </button>
    <!-- <button mat-menu-item><a href="/download/PlanAProject-plugin.rbz" target="_blank">Download Sketchup Extension</a></button> -->
  </mat-menu>

  <!-- <a href="/"><img id="icon" src="assets/logo-smartworks2.svg"/></a> -->

  <div class="spacer"></div>
  <div [matMenuTriggerFor]="userMenu" id="user">
    <mat-icon>account_box</mat-icon>
    <span id="username">
      {{ (authService.user$ | async).username }}
    </span>
  </div>
  <mat-menu #userMenu="matMenu">
    <a mat-menu-item (click)="openProfile()">
      <mat-icon>account_box_outline</mat-icon>Profile
    </a>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="logout()">
      <mat-icon>logout</mat-icon>Logout
    </button>
  </mat-menu>
</mat-toolbar-row>
